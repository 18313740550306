import { useNewsLetterSubscription } from '../api/subscribe';
import { showToast } from '../toast/helper';

export const useSubscribe = () => {
    const { trigger, data, error, isMutating } = useNewsLetterSubscription();

    const handleSubscribe = async (email: string): Promise<{ error?: string }> => {
        try {
            const response = await trigger({ email });
            if (response.subscribed) {
                showToast('success', response.message || 'Subscription successful');
                return {};
            } else if (response.error) {
                return { error: response.error };
            }
        } catch (err) {
            return { error: 'Already Subscribed' };
        }
        return {};
    };

    return {
        handleSubscribe,
        data,
        error,
        isMutating,
    };
};

'use client';

import React from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { Flex } from '@/components/atoms/flex';
import { PrimaryButton } from '@/components/atoms/button';
import { validationShema } from './validation';
import { useSubscribe } from '@/libs/hooks/useSubscribe';

interface FormValues {
    email: string;
}

const initialValues: FormValues = {
    email: '',
};

const SubscriptionForm: React.FC = () => {
    const { handleSubscribe, isMutating } = useSubscribe();

    const handleSubmit = async (
        values: FormValues,
        { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
    ) => {
        const response = await handleSubscribe(values.email);
        if (response?.error) {
            setFieldError('email', response.error);
        }
        setSubmitting(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationShema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Flex
                        className='w-fit rounded-full border border-neutral-300 p-2'
                        alignItems='center'
                        gapX={1}
                        justifyContent='evenly'
                    >
                        <Field
                            type='email'
                            name='email'
                            placeholder='Enter your Email'
                            className='border-none text-sm placeholder:text-neutral-400 focus:border-none focus:outline-none focus:ring-0 active:border-none sm:text-base'
                        />
                        <PrimaryButton
                            type='submit'
                            disabled={isSubmitting || isMutating}
                            className='rounded-full'
                        >
                            Subscribe
                        </PrimaryButton>
                    </Flex>
                    <ErrorMessage name='email' component='div' className='text-xs text-red-600' />
                </Form>
            )}
        </Formik>
    );
};

export default SubscriptionForm;

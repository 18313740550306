'use client';

import { Timeline } from 'flowbite-react';
import { useRoadMap } from '@/libs/hooks/use-roadmap';
import { FC } from 'react';
import { getKey } from '@/libs/utils/helpers';

interface RoadMapData {
    name: string;
    goals: string[];
}

const RoadMap: FC = () => {
    const { data, error, isLoading } = useRoadMap();

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading Roadmap data</div>;

    return (
        <div className='flex flex-row'>
            {data.map((item: RoadMapData, index: number) => (
                <Timeline horizontal key={index} className='mx-4'>
                    <Timeline.Item>
                        <Timeline.Point />
                        <Timeline.Content>
                            <Timeline.Time>{'2024'}</Timeline.Time>
                            <Timeline.Title>{item.name}</Timeline.Title>

                            <Timeline.Body>
                                <ul>
                                    {item.goals.map((goal) => (
                                        <li key={getKey(3)}>{goal}</li>
                                    ))}
                                </ul>
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>
                </Timeline>
            ))}
        </div>
    );
};

export default RoadMap;

import { ISWRMutationConfiguration } from '@/types/types';
import useSWRMutation from 'swr/mutation';
import { postReq } from '../utils/fetcher';

const endpoint = '/api/blog/subscribe';

interface SubscriptionData {
    email: string;
}

export const useNewsLetterSubscription = (
    config?: ISWRMutationConfiguration<any, SubscriptionData, any>,
) => {
    return useSWRMutation(endpoint, postReq<SubscriptionData>, config);
};
